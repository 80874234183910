import instance from "@/axios"

/** 添加备注信息 */
export const getNoteListApi = async (params, apiUrl) => {
    // return instance({
    //     url: apiUrl,
    //     method: 'GET',
    //     params: params,
    // })
    return new Promise((resolve, reject) => {
        resolve({
            data: []
        })
    })
}
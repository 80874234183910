<template>
  <div class="flex-colum-start-start no-ml">
    <a-popconfirm :title="row.waitHandleStatus ? '确定取消标记处理中？' : '确定标记处理中？'" @confirm="handleConfirmActionOver(row.id, row.waitHandleStatus)">
      <a-checkbox
          :checked='!!row.waitHandleStatus'
          :class="row.waitHandleStatus ? 'color-blue' : 'color-black'"
      >{{ row.waitHandleStatus ? '已标记为处理中' : '标记为处理中' }}</a-checkbox>
    </a-popconfirm>
  </div>
</template>
<script>
import {postRateServiceTagActionOverApi} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_apis"

export default {
  props: ['row'],
  emits: ['changeSuccess'],
  data() {
    return {}
  },
  methods: {
    /** 标记已处理 */
    async handleConfirmActionOver(id, status) {
      const params = {
        id: id,
        waitHandleStatus: status ? 0 : 1,
      }
      const res = await postRateServiceTagActionOverApi(params)
      if (res.status !== '200') return
      this.$message.info('标记成功')
      this.$emit('changeSuccess')
    },
  }
}
</script>
<style scoped lang="scss">
.no-ml::v-deep .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0 !important;
}
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-colum-start-start no-ml" },
    [
      _c(
        "a-popconfirm",
        {
          attrs: {
            title: _vm.row.waitHandleStatus
              ? "确定取消标记处理中？"
              : "确定标记处理中？"
          },
          on: {
            confirm: function($event) {
              return _vm.handleConfirmActionOver(
                _vm.row.id,
                _vm.row.waitHandleStatus
              )
            }
          }
        },
        [
          _c(
            "a-checkbox",
            {
              class: _vm.row.waitHandleStatus ? "color-blue" : "color-black",
              attrs: { checked: !!_vm.row.waitHandleStatus }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.row.waitHandleStatus ? "已标记为处理中" : "标记为处理中"
                )
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
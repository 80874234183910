<template>
  <div class="order-log-list-info-pages">
    <div class="w-100-w flex-end-center">
      <a-button @click="handleAddCenterNotesByForward()">新增备注</a-button>
    </div>
    <template v-if="noteList && noteList.length">
      <div class="mt-10 order-log-item" v-for="(item,index) of noteList" :key="index">
        <div class="order-item-left">{{ index + 1 }}</div>
        <div class="order-item-right">
          <div class="font-weight-bold color-gray">{{item.createTime}}</div>
          <div class="font-weight-bold color-black mt-5">{{item.sysAccountName}}【<span class="color-blue">{{ item.sysAccountId }}</span>】</div>
          <div class="mt-5">
            <span>备注内容：</span>
            <span class="font-weight-bold color-black">{{ item.content }}</span>
          </div>
          <div class="mt-5 flex-start-center flex-wrap">
            <img
                class="w-50 h-50"
                v-for="(src, index) in item.pictureList"
                :key="index" :src="src" alt=""
                @click="handleShowBigImg(item.pictureList, index)"
            >
          </div>
        </div>
      </div>
    </template>
    <a-empty v-else />
<!--  新增备注  -->
    <AddNotePopup ref="addNotePopupEl" @success="handleAddSuccess" />
  </div>
</template>
<script>
import _showBigImgMixin from "@/_mixins/_showBigImgMixin"
import AddNotePopup from "@/components/NoteListInfoAbout/_components/AddNotePopup/index.vue"

export default {
  components: {AddNotePopup},
  mixins: [_showBigImgMixin],
  props: ['noteList', 'noteType', 'noteParams'],
  emits: ['changeSuccess'],
  data() {
    return {}
  },
  methods: {
    /** 添加成功 */
    handleAddSuccess() {
      this.$emit('changeSuccess')
    },
    /** 新增备注 */
    async handleAddCenterNotesByForward() {
      this.$refs.addNotePopupEl.show(this.noteType, this.noteParams || {})
    },
  }
}
</script>
<style lang="scss" scoped>
.order-log-list-info-pages {
  width: 100%;

  .order-log-item {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .order-item-left {
      margin-right: 20px;
      width: 30px !important;
      height: 30px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      background-color: gray;
    }

    .order-item-right {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}
</style>
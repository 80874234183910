<template>
  <a-modal
      v-model="showPopup"
      :width='500'
      title="添加内部备注"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <div>
      <a-input
          allowClear
          v-model="params.content"
          size="small"
          type="textarea"
          :auto-size="{ minRows: 4, maxRows: 10 }"
          class="w-100-w"
          placeholder='内部备注，不对外展示'
      ></a-input>
    </div>
    <div class="mt-20">
      <a-upload
          :action="ip + '/file/upload'"
          list-type="picture-card"
          :file-list="coverList"
          :multiple="true"
          @preview="handlePreview"
          @change="handleChangeCoverList"
      >
        <div v-if="coverList.length < 15">
          <a-icon type="plus" />
          <div class="ant-upload-text">上传图片</div>
        </div>
      </a-upload>
    </div>
  </a-modal>
</template>
<script>
import {mapState} from "vuex"
import {addNoteInfoApi} from "@/components/NoteListInfoAbout/_apis"

export default {
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      coverList: [],
      params: {
        // [key: string]: undefined, 会根据传递的参数自动生成
        content: undefined,
        pictureList: []
      },
      rateTypeMapApiUrl: {

      },
      noteType: undefined,
    }
  },
  computed: {
    ...mapState(['ip']),
  },
  methods: {
    show(noteType, noteParams) {
      this.noteType = noteType
      const objKeys = Object.keys(noteParams)
      if (objKeys.length) {
        objKeys.forEach(key => {
          this.params[key] = noteParams[key]
        })
      }
      this.showPopup = true
    },
    handleChangeCoverList({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
            row.thumbUrl = row.url
          }
        } catch (e) {}
      });
      this.coverList = fileList;
    },
    /** 确定修改 */
    async handleConfirm() {
      if (!this.params.content) return this.$message.warning('内容不能为空')
      if (this.coverList.length) {
        if (!this.coverList.every(el => el.status === 'done')) return this.$message.warn('图片上传中，请稍等')
        this.params.pictureList = this.coverList.map((row) => {
          return row.url;
        })
      }
      const res = await addNoteInfoApi(this.params, this.rateTypeMapApiUrl[this.noteType])
      if (res.status !== '200') return
      this.$message.info('添加成功')
      this.handleCancel()
      this.$emit('success')
    },
    /** 取消 */
    handleCancel() {
      this.coverList = []
      this.noteType = undefined
      this.params = {
        // [key: string]: undefined, 会根据传递的参数自动生成
        content: undefined,
        pictureList: []
      }
      this.showPopup = false
    },
    handlePreview(file, type) {
      this.$previewImg({
        list: [{img_url: `${file.url}`}],
        baseImgField: "img_url",
        showMute: false,
      });
    },
  }
}
</script>
/** 管理列表 */
export const dqCheckRatingManageTableColumns = [
    { title: "送评人", align: "center", scopedSlots: {customRender: 'itemProductNameSlot'}, width: 200 },
    { title: "订单号/D类送评单号", align: "center", scopedSlots: { customRender: "itemOrderNoSlot" }, width: 200 },
    { title: "送评状态", align: "center", scopedSlots: { customRender: "itemRatingStatusSlot" }, width: 200},
    { title: "评级费用", align: "center", scopedSlots: { customRender: "itemRatingPriceSlot" }, width: 200},
    { title: "送评物流", align: "center", scopedSlots: { customRender: 'itemSellerExpressNoSlot' }, width: 200},
    { title: "寄回物流", align: "center", scopedSlots: { customRender: 'itemSendExpressNoSlot' }, width: 200},
    { title: "订单日期", align: "center", dataIndex: 'createTime' },
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" }, width: 200 },
]

export const sonTableColumns = [
    { title: "评级编码", align: "center", dataIndex: 'ratingCode' },
    { title: "短码", align: "center", scopedSlots: { customRender: 'itemShortCodeSlot' }, width: 200 },
    { title: "评级费", align: "center", dataIndex: 'ratingPrice' },
    { title: "标版费", align: "center", dataIndex: 'duibanPrice' },
]

/** 检索等待买家，卖家选择状态列表 */
export const checkAwaitBuyerOrSellerSelectedStatusList = [
    { name: '买家待选择', value: 10 },
    { name: '卖家待选择', value: 20 }
]
<template>
  <a-modal v-model="showPopup" :width='500' title="添加备注" @cancel="handleCancel" @ok="handleConfirm">
    <div>
      <a-input
          allowClear
          v-model="params.remarks"
          size="small"
          type="textarea"
          :auto-size="{ minRows: 4, maxRows: 10 }"
          class="w-100-w"
          placeholder='内部备注，不对外展示'
      ></a-input>
    </div>
  </a-modal>
</template>
<script>
import {addRatingNoteApi} from "@/views/cmsPage/checkManage/ratingManage/_apis"

export default {
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      params: {
        id: undefined,
        remarks: undefined,
      },
    }
  },
  methods: {
    show(id) {
      this.params.id = id
      this.showPopup = true
    },
    /** 确定修改 */
    async handleConfirm() {
      this.$loading.show()
      const res = await addRatingNoteApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('添加成功')
      this.handleCancel()
      this.$emit('success')
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        id: undefined,
        remarks: undefined,
      }
      this.showPopup = false
    },
  }
}
</script>
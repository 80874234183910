<template>
  <a-modal v-model="showPopup" title="备注列表" :width="800">
    <template #footer><div></div></template>
    <NoteListInfoAbout
        :note-list="noteList"
        :noteParams="noteParams"
        :note-type="noteType"
        @changeSuccess="handleChangeSuccess"
    />
  </a-modal>
</template>
<script>
import NoteListInfoAbout from "@/components/NoteListInfoAbout/index.vue"
import {getNoteListApi} from "@/components/NoteMessagePopup/_apis"
export default {
  components: {NoteListInfoAbout},
  props: ['noteType'],
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      noteList: [],
      noteParams: {},
      noteTypeMapNoteListApiUrl: {
        rateMange: '',
      }
    }
  },
  methods: {
    /** 展示弹窗 */
    async show(noteParams) {
      this.noteParams = noteParams || {}
      await this.getNoteList()
      this.showPopup = true
    },
    async getNoteList() {
      const res = await getNoteListApi(this.noteParams, this.noteTypeMapNoteListApiUrl[this.noteType])
      if (res.status !== '200') return
      this.noteList = res.data
    },
    handleChangeSuccess() {
      this.$emit('success')
    }
  }
}
</script>
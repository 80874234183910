var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "备注列表", width: 800 },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [_c("div")]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c("NoteListInfoAbout", {
        attrs: {
          "note-list": _vm.noteList,
          noteParams: _vm.noteParams,
          "note-type": _vm.noteType
        },
        on: { changeSuccess: _vm.handleChangeSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }